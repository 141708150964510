import { FC } from "react";

type Props = {
  color?: string;
};

export const EmailIcon: FC<Props> = ({ color = "#315C2B" }) => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 147 147"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M73.3739 146.748C113.897 146.748 146.748 113.897 146.748 73.3739C146.748 32.8506 113.897 0 73.3739 0C32.8506 0 0 32.8506 0 73.3739C0 113.897 32.8506 146.748 73.3739 146.748ZM73.3739 137.113C108.576 137.113 137.113 108.576 137.113 73.3739C137.113 38.1719 108.576 9.63497 73.3739 9.63497C38.1718 9.63497 9.63496 38.1719 9.63496 73.3739C9.63496 108.576 38.1718 137.113 73.3739 137.113Z"
      fill={color}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M114.137 45.9514H31.1283V47.1212L71.0733 82.9241C71.8329 83.605 72.9834 83.605 73.743 82.9241L113.949 46.887C114.014 46.9183 114.077 46.9529 114.137 46.9907V45.9514ZM114.137 50.4244L73.743 86.6299C72.9834 87.3108 71.8329 87.3108 71.0733 86.6299L31.1283 50.827V97.8319H114.137V50.4244Z"
      fill={color}
    />
  </svg>
);

export default EmailIcon;
