import dayjs from "dayjs";
import "dayjs/locale/sv";
import { client } from ".";
dayjs.locale("sv");

export type ScheduleType = {
  location: string;
  weeklyEducations: {
    service: string;
    day: string;
    date: string;
    time: string;
    location: string;
  }[];
};

export const getSchedule = async (): Promise<ScheduleType[]> => {
  const response = await client.getEntries({
    content_type: "week",
  });

  return response.items.map((week: any) => {
    const weeklyEducations = week.fields.weekDetails.map((item: any) => {
      const day = dayjs(item.fields.startdate).format("dddd") ?? "";
      const date = dayjs(item.fields.startdate).format("D/M - YY") ?? "";
      const startTime = dayjs(item.fields.startdate).format("HH.mm") ?? "";
      const endTime = dayjs(item.fields.enddate).format("HH.mm") ?? "";

      return {
        service: item.fields.education ?? "",
        day,
        date,
        time: `${startTime} - ${endTime}`,
      };
    });

    return {
      location: week.fields.location,
      weeklyEducations,
    };
  });
};

// const client = createClient({
//   space: '<your-space-id>',
//   accessToken: 'XAGwInL6NAXtmGPlKOo6PsXXQ5S25e_5HeI0XmoYFFk'
// })

// client.getEntries().then((entries) => {
//   console.log(entries.items)
// })
