import Schaffolding from "../../assets/pexelsStallning.jpg";
import styled, { css } from "styled-components";
import { FlexContainer } from "../WrapperElements";
import { FC, useEffect, useState } from "react";
import { ServiceType, getServices } from "../../contentful/getServices";
// import scaffolding from "../../assets/scaffolding.jpg";

export const Services: FC = () => {
  const [services, setServices] = useState<ServiceType[]>([]);

  useEffect(() => {
    const fetchService = async () => {
      try {
        const res = await getServices();
        const sorted = res.sort((a, b) => {
          return a.educationName.localeCompare(b.educationName);
        });
        setServices(sorted);
      } catch (error) {
        console.log(error);
      }
    };

    if (!services.length) {
      fetchService();
    }
  }, [services]);

  return (
    <FlexContainer centeredJusitify>
      <StyledOurServices>
        <StyledBigPhotos>
          <BigPhoto />
        </StyledBigPhotos>

        <InfoWrapper>
          <h2 id="services">UTBILDNINGAR</h2>
          <CardsContainer>
            {services.map((service, index) => (
              <StyledLink
                key={service.educationName}
                href={service.pdf ? service.pdf : undefined}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Card key={index} hasPdf={!!service.pdf}>
                  {service.educationName}
                </Card>
              </StyledLink>
            ))}
          </CardsContainer>

          <p>
            <i>
              Alla utbildare är ackrediterade för att registrera på ID06
              kompetensdatabas. Enligt de krav som ställs under 2023.
              <br />
              Vi håller öppna utbildningar på olika platser i Sverige.
              Lärarledda webbutbildningar och repetitionsutbildningar, även
              internutbildning på plats hos kund. Begär offert för mer
              information.
            </i>
          </p>
        </InfoWrapper>
      </StyledOurServices>
    </FlexContainer>
  );
};

const StyledOurServices = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 35px;
`;

const StyledBigPhotos = styled.div`
  display: flex;
  padding: 30px 0 30px 0;

  @media screen and (max-width: 950px) {
    display: none;
  }
`;

const InfoWrapper = styled.div`
  padding: var(--main-padding);
`;

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 8px;

  @media screen and (max-width: 950px) {
    grid-template-columns: 50% 50%;
  }
`;

const StyledLink = styled.a`
  text-decoration: none;
  color: var(--color-black);
`;

const Card = styled.div<{ hasPdf: boolean }>`
  background-color: #f7f9f7;
  padding: 8px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 70px;
  height: 100%;

  &:hover {
    ${(p) =>
      p.hasPdf &&
      css`
        transform: scale(1.04);
        background-color: #ebf4eb;
      `};
  }
`;

const BigPhoto = styled.div`
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-image: url(${Schaffolding});
  background-position: bottom;
  background-size: cover;
  height: 55vh;
  min-height: 500px;
  width: 100%;
`;
