import Contact from "./main/contact";
import ImageCarousel from "./main/imageCarousel";
import { Schedule } from "./main/schedule";
import { Services } from "./main/services";

const MainPage = () => (
  <>
    <Contact />
    <Services />
    <ImageCarousel />
    <Schedule />
  </>
);

export default MainPage;
