import { FC } from "react";

type Props = {
  color?: string;
};

export const PhoneIcon: FC<Props> = ({ color = "#315C2B" }) => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 147 147"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M73.6261 146.748C114.149 146.748 147 113.897 147 73.3739C147 32.8506 114.149 0 73.6261 0C33.1028 0 0.252197 32.8506 0.252197 73.3739C0.252197 113.897 33.1028 146.748 73.6261 146.748ZM73.6261 137.113C108.828 137.113 137.365 108.576 137.365 73.3739C137.365 38.1719 108.828 9.63497 73.6261 9.63497C38.4241 9.63497 9.88717 38.1719 9.88717 73.3739C9.88717 108.576 38.4241 137.113 73.6261 137.113Z"
      fill={color}
    />
    <circle
      cx="44.2061"
      cy="97.3677"
      r="15.5642"
      transform="rotate(13.4366 44.2061 97.3677)"
      fill={color}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M46.7154 102.948C58.05 100.755 70.7239 93.5276 83.0946 82.1383L95.088 92.344C72.5639 113.545 48.8755 120.991 33.9815 109.388C39.055 109.443 43.8032 106.987 46.7154 102.948Z"
      fill={color}
    />
    <ellipse
      rx="16.0791"
      ry="15.7768"
      transform="matrix(0.187797 0.982208 0.989677 -0.143319 99.0857 46.9589)"
      fill={color}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M104.999 50.1988C101.959 61.0172 94.015 72.4345 82.1376 82.962L90.8363 96.1908C112.904 77.0888 121.982 54.8865 112.198 38.6106C111.824 43.6004 109.087 47.9137 104.999 50.1988Z"
      fill={color}
    />
  </svg>
);

export default PhoneIcon;
